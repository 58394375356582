import { Button, Divider, Form, Input, InputNumber, Layout, Space, Spin, message } from "antd";
import {
    DeleteOutlined
} from '@ant-design/icons';
import PageHeader from "../../comps/pageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProf, setAddProfResult } from "./profsSlice";
import { useCookies } from "react-cookie";
import { addProflessClassroom, setAddProflessClassroom } from "../classrooms/classroomsSlice";

const { Content } = Layout;

export default function AddProf() {
    const [cookies] = useCookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAddingProf = useSelector((state) => state.profs.isAddingProf);
    const addProfResult = useSelector((state) => state.profs.addProfResult);
    const proflessClassResult = useSelector((state) => state.classrooms.addProflessClassroom);


    const [formData, setFormData] = useState({
        name: "",
        phoneNumber: "",
        email: "",
        password: "",
        nAccessSeats: 1,
        classIds: []
    });

    const [classes, setClasses] = useState([]);
    const [classNameToBeAdded, setClassNameToBeAdded] = useState("");

    useEffect(() => {
        setClassNameToBeAdded("");
        setClasses([]);
        setFormData({ ...formData, classIds: [] });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!addProfResult) {
            return;
        }
        message.success(addProfResult.message);
        dispatch(setAddProfResult(null));
        setTimeout(() => {
            navigate("/dashboard/profs/profList")
        }, 2000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addProfResult]);

    const addClassToList = (classroom) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            classIds: [...prevFormData.classIds, classroom._id],
        }));
        setClasses([...classes, classroom]);
        setClassNameToBeAdded("");
    }

    const removeClassFromList = (classroom) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            classIds: prevFormData.classIds.filter(classId => classId !== classroom._id),
        }));
        setClasses(classes.filter(localClassroom => localClassroom._id !== classroom._id));
    }

    useEffect(() => {
        if (!proflessClassResult) {
            return;
        }
        addClassToList(proflessClassResult);
        dispatch(setAddProflessClassroom(null));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proflessClassResult])

    const handleCreate = () => {
        // Validation is hard haha
        // if I ever come back here, I'm refactoring it and using JOI
        if (!formData.name) {
            return message.error("Il manque le nom.");
        }
        if (!formData.email) {
            return message.error("Il manque l'email.");
        }
        if (!formData.password) {
            return message.error("Il manque le mot de passe.");
        }
        if (!formData.phoneNumber) {
            return message.error("Il manque le numéro de téléphone.");
        }
        if (formData.nAccessSeats < 1) {
            return message.error("Le nombre de code est inférieur à 1.");
        }
        dispatch(addProf(cookies, formData));
    }

    return (
        <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
            <Layout>
                <PageHeader title="Ajout d'un prof" breadcrumbItems={["Vue d'ensemble", "Ajouter un prof"]} />
                <Content>
                    <div
                        style={{
                            margin: "2em",
                            padding: "1em 2em 1em 2em",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            backgroundColor: "#fff"
                        }}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}>
                            <h3>Informations pertninentes</h3>
                        </div>
                        <Form
                            style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
                            name="notExistsForm"
                            layout="vertical"
                            initialValues={formData}
                        >
                            <div style={{ display: "flex", width: "40%", flexDirection: "column" }}>
                                <Form.Item
                                    label="Entrer le nom du prof"
                                    name="name"
                                    rules={[
                                        { required: true, message: 'Veuillez insérer un nom.' }
                                    ]}
                                >
                                    <Input
                                        placeholder="Prénom NOM"
                                        value={formData.name}
                                        onChange={(e) => { setFormData({ ...formData, name: e.target.value }) }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Adresse mail"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Veuillez insérer un email.' }
                                    ]}
                                >
                                    <Input
                                        placeholder="prof.adresse@établissement.com"
                                        // prefix={<UserOutlined className="site-form-item-icon" />}
                                        value={formData.email}
                                        onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Envoyer"
                                    name="nAccessSeats"
                                    rules={[
                                        { required: true, message: 'Veuillez insérer un email.' }
                                    ]}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}>
                                        <InputNumber
                                            max={Number.MAX_VALUE}
                                            min={1}
                                            value={formData.nAccessSeats}
                                            onChange={(e) => { setFormData({ ...formData, nAccessSeats: e }) }}
                                        />
                                        <p style={{ marginLeft: "8px" }}>Codes</p>
                                    </div>
                                </Form.Item>
                                {/* TODO: Create a list once the class CRUD is made */}
                                <Form.Item
                                    label="Classes"
                                    name="classes"
                                    rules={[
                                        { required: false, message: 'Veuillez insérer un nom de classe.' }
                                    ]}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        
                                    }}>
                                        {classes.map((classroomData, index) => (
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                marginBottom: "8px",
                                                width:"100%"
                                            }}>
                                                <Input disabled={true} key={index} value={classroomData.name}></Input>
                                                <Button
                                                    className="buttonStyle"
                                                    style={{ width: "4vw", marginLeft: "8px", backgroundColor: "#FF4D4F" }}
                                                    onClick={() => removeClassFromList(classroomData)}
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}>
                                        <Input
                                            placeholder="Nom"
                                            value={classNameToBeAdded}
                                            onChange={(e) => setClassNameToBeAdded(e.target.value)}
                                        />
                                        <Button
                                            className="buttonStyle"
                                            style={{ width: "4vw", marginLeft: "8px" }}
                                            onClick={() => dispatch(addProflessClassroom(cookies, { className: classNameToBeAdded }))}
                                        >
                                            Ajouter
                                        </Button>
                                    </div>
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", width: "40%", flexDirection: "column" }}>
                                <Form.Item
                                    label="Numéro de téléphone"
                                    name="phone"
                                    rules={[
                                        { required: true, message: 'Veuillez insérer un numéro de téléphone.' }
                                    ]}
                                >
                                    <Input
                                        placeholder="+33 XXX - XXX - XXX"
                                        value={formData.phoneNumber}
                                        onChange={(e) => { setFormData({ ...formData, phoneNumber: e.target.value }) }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Mot de passe"
                                    name="password"
                                    rules={[
                                        { required: true, message: 'Veuillez insérer un mot de passe.' }
                                    ]}
                                >
                                    <Input.Password
                                        value={formData.password}
                                        onChange={(e) => { setFormData({ ...formData, password: e.target.value }) }}
                                    />
                                </Form.Item>
                                <div style={{ justifyContent: "start" }}>
                                    <p style={{ color: "#00000073" }}>Le Mot de Passe doit comporter au moins :</p>
                                    <ul style={{ paddingLeft: "16px", listStyle: "none" }}>
                                        <li style={{ color: "#00000073" }}>De 8 à 12 caractères</li>
                                        <li style={{ color: "#00000073" }}>Au moins 1 minuscule</li>
                                        <li style={{ color: "#00000073" }}>Au moins 1 majuscule</li>
                                        <li style={{ color: "#00000073" }}>Au moins 1 chiffre</li>
                                    </ul>
                                </div>
                            </div>
                        </Form>
                        <Divider />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <p style={{ marginBottom: "8px" }}>Vérifiez les informations avant de confirmer.</p>
                            <Button
                                className="buttonStyle"
                                onClick={handleCreate}
                            >
                                {isAddingProf ? <Spin /> : "Ajouter"}
                            </Button>
                        </div>

                    </div>

                </Content>

            </Layout>
        </Space>
    )
}