import { Button, Divider, Input, Layout, Modal, Select, Space, Spin, Table, Tooltip, message } from "antd";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../comps/pageHeader";
// import { useNavigate } from "react-router-dom";
import { fetchNamedProfs } from '../prof/profsSlice';
import { useEffect, useState } from "react";
import { addClassroom, fetchClassroomList, modifyClassroom } from "./classroomsSlice";
import { SearchOutlined } from '@ant-design/icons';

export default function ClassroomList({ pros }) {

    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookies] = useCookies();
    // const navigate = useNavigate();

    const [fetchData, setFetchData] = useState({
        className: null,
        profId: null,
        page: 1,
        docsPerPage: 10,
    });

    const [addFormData, setAddFormData] = useState({
        email: null,
        className: null,
    });

    const [modifyFormData, setModifyFormData] = useState({
        id: null,
        profEmail: "",
        className: "",
    });

    const [classnameDisplay, setClassnameDisplay] = useState("")
    const [profListData, setProfListData] = useState([]);
    const [timeout, setTimeoutVal] = useState(0);
    const [addClassModalOpen, setAddClassModalOpen] = useState(false);
    const [modifyClassModalOpen, setModifyClassModalOpen] = useState(false);

    const isFetchingClassroomList = useSelector((state) => state.classrooms.isFetchingClassroomList);
    const classroomList = useSelector((state) => state.classrooms.classroomList);
    const classroomsCount = useSelector((state) => state.classrooms.totalClassrooms);
    const namedProfsList = useSelector((state) => state.profs.namedProfsList);
    const addClassroomResult = useSelector((state) => state.classrooms.addClassroomResult);
    const isAddingClassRoom = useSelector((state) => state.classrooms.isAddingClassroom);
    const modifyClassroomResult = useSelector((state) => state.classrooms.modifyClassroomResult);
    const isModifyingClassRoom = useSelector((state) => state.classrooms.isModifyingClassroom);

    useEffect(() => {
        dispatch(fetchClassroomList(cookies, fetchData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);

    useEffect(() => {
        if (!namedProfsList || namedProfsList.length === 0) return;
        setProfListData([{ value: null, label: "Réinitialiser" }].concat(namedProfsList));
    }, [namedProfsList]);

    useEffect(() => {
        if (!addClassroomResult) return;

        setAddFormData({ email: null, className: null });
        setAddClassModalOpen(false);

        dispatch(fetchClassroomList(cookies, fetchData));

        message.success("Classe créée avec succès.");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addClassroomResult]);

    useEffect(() => {
        if (!modifyClassroomResult) return;

        setModifyFormData({ email: null, className: null });
        setModifyClassModalOpen(false);

        dispatch(fetchClassroomList(cookies, fetchData));

        message.success("Classe modifiée avec succès.");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modifyClassroomResult]);


    const onAddClassPressed = () => {
        dispatch(addClassroom(cookies, addFormData));
    }

    const onModifyClassPressed = () => {
        dispatch(modifyClassroom(cookies, modifyFormData));
    }

    const timedSearch = (data) => {
        setClassnameDisplay(data);
        let baseText = data;
        if (timeout) clearTimeout(timeout);
        setTimeoutVal(setTimeout(() => {
            let text = baseText ? baseText.replaceAll("-", "") : null;
            setFetchData({ ...fetchData, className: text });
        }, 200));
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Tooltip placement="top" title={"Veuillez insérer le nom d'une classe"}>
                    <Input
                        // ref={searchInput}
                        placeholder={`classe`}
                        value={classnameDisplay}
                        onChange={(e) => timedSearch(e.target.value)}

                        //   onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                </Tooltip>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => dispatch(fetchClassroomList(cookies, fetchData))}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Chercher
                    </Button>
                    <Button
                        onClick={() => { setFetchData({ fetchData, className: null }); }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            render: (text) => (<p>{text}</p>),
            width: "18vw",
            ...getColumnSearchProps('name')
        },
        {
            title: 'Prof attribué',
            dataIndex: 'prof',
            key: 'prof',
            render: (data) => (<p>{data ? data.name : ""}</p>),
            width: "18vw",
            // ...getColumnSearchProps('code')
        },
        {
            title: 'Nbr. d’élèves',
            dataIndex: 'userCount',
            key: 'userCount',
            render: (text) => (<p>{text}</p>),
            width: "18vw",
            // ...getColumnSearchProps('code')
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: '_id',
            render: (data, record) => (
                <div >
                    <Button
                        style={{ width: "6vw", marginRight: "8px", color: "#39B13D", border: "none" }}
                        onClick={() => { setModifyFormData({ ...modifyFormData, id: data }); setModifyClassModalOpen(true); }}
                    >
                        Modifier
                    </Button>
                </div>
            ),
            width: "18vw",
            // ...getColumnFilterStatusProps('sent')
        }

    ];

    const onSearchProf = (name) => {
        if (!name) return;
        dispatch(fetchNamedProfs(cookies, { name }));
    }

    const onChangeProf = (e) => {
        if (!e) {
            setFetchData({ ...fetchData, profId: null });
            setProfListData([]);
            return;
        }

        setFetchData({ ...fetchData, profId: e });
    }

    const onChangePagination = (e) => {
        setFetchData({
            ...fetchData,
            docsPerPage: e.pageSize,
            page: e.current
        });
    }

    const closeAddModal = () => {
        setAddClassModalOpen(false);
    }

    const closeModifyModal = () => {
        setModifyClassModalOpen(false);
    }

    return (
        <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
            <Layout>
                <PageHeader title="Liste des classes" breadcrumbItems={["gestion des classes", "Classes"]} />
                <div style={{
                    margin: "2em",
                    padding: "2em 4vw 2em 4vw",
                    display: "flex",
                    direction: "column",
                    justifyContent: "space-around",
                    backgroundColor: "#424242"
                }}>
                    <div className="buttonContainer">
                        <p style={{ color: "#fff", fontWeight: "600" }}>Ajouter une nouvelle classe :</p>
                        <Button
                            className="buttonStyle"

                            onClick={() => setAddClassModalOpen(true)}
                        >
                            Ajouter
                        </Button>
                    </div>
                </div>
                <div
                    style={{
                        margin: "2em",
                        padding: "1em 2em 1em 2em",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        backgroundColor: "#fff"
                    }}
                >
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <h3>Liste des classes</h3>
                        <div
                            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                        >
                            <p style={{ marginRight: "12px" }}>Filtrer par prof :</p>
                            <Select
                                showSearch
                                placeholder="Séléctionner prof"
                                optionFilterProp="label"
                                style={{
                                    width: "240px"
                                }}
                                onChange={onChangeProf}
                                onSearch={onSearchProf}
                                options={profListData}
                            />
                        </div>
                    </div>
                    <Divider />
                    <Table
                        loading={isFetchingClassroomList}
                        dataSource={classroomList}
                        pagination={{
                            total: classroomsCount,
                            current: fetchData.page,
                            onChange: (e) => console.log(e),
                            pageSize: fetchData.docsPerPage,
                            showSizeChanger: true,
                            pageSizeOptions: [10, 15, 20, 30, 40, 50], // , 15, 20, 30, 40, 50
                            showQuickJumper: true,
                            showTotal: (total, range) => (<>Total des classes : {total} </>),
                        }}
                        columns={columns}
                        onChange={onChangePagination}
                    />
                    {/* Add Modal */}
                    <Modal
                        title="Ajouter une classe"
                        open={addClassModalOpen}
                        cancelText="Annuler"
                        onCancel={closeAddModal}
                        okText={isAddingClassRoom ? <Spin /> : "Ajouter"}
                        onOk={onAddClassPressed}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <p style={{ margin: "12px 0px" }}>Nom de la classe</p>
                            <Input
                                placeholder="####"
                                value={addFormData.className}
                                onChange={(e) => { setAddFormData({ ...addFormData, className: e.target.value }) }}
                            />
                            <p style={{ margin: "12px 0px" }}>Prof attribué</p>
                            <Input
                                placeholder="prof.addresse@établissement.com"
                                value={addFormData.email}
                                onChange={(e) => { setAddFormData({ ...addFormData, email: e.target.value }) }}
                            />
                        </div>
                    </Modal>
                    {/* Edit Modal */}
                    <Modal
                        title="Modifier une classe"
                        open={modifyClassModalOpen}
                        cancelText="Annuler"
                        onCancel={closeModifyModal}
                        okText={isModifyingClassRoom ? <Spin /> : "Ajouter"}
                        onOk={onModifyClassPressed}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <p style={{ margin: "12px 0px" }}>Nom de la classe</p>
                            <Input
                                placeholder="####"
                                value={modifyFormData.className}
                                onChange={(e) => { setModifyFormData({ ...modifyFormData, className: e.target.value }) }}
                            />
                            <p style={{ margin: "12px 0px" }}>Prof attribué</p>
                            <Input
                                placeholder="prof.addresse@établissement.com"
                                value={modifyFormData.profEmail}
                                onChange={(e) => { setModifyFormData({ ...modifyFormData, profEmail: e.target.value }) }}
                            />
                        </div>
                    </Modal>
                </div>
            </Layout>

        </Space>
    );
}