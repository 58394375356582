const base_url = "https://cactusfrance.com/api/pro"

export const login_url = base_url + "/signin";
export const profile_url = base_url + "/getProfile";
export const getCodesCountUrl = base_url + "/getCodesCount";
export const getCodesUrl = base_url + "/getActivationCodes";
export const getModulesExportOptionsUrl = base_url + "/getDistinctModuleExportOptions";
export const ExportPDFUrl = base_url + "/exportCodesToPDF";
export const getActivationLinksUrl = base_url + "/getActivationLinks";
export const sendActivationCodesEmailUrl = base_url + "/sendActivationCodesEmail";

// Prof routes
export const getProfsUrl = base_url + "/getProfList";
export const addProfUrl = base_url + "/addProf"
export const assignCodesToProfUrl = base_url + "/assignCodesToProf";
export const suspendProfUrl = base_url + "/suspendProf";
export const getSingleProfUrl = base_url + "/getSingleProf";
export const updateProfUrl = base_url + "/updateProf";
export const reclaimAllProfsCodesUrl = base_url + "/reclaimAllProfsCodes";
export const reclaimSingleProfCodesUrl = base_url + "/reclaimSingleProfCodes";
export const getNumberOfReclaimableCodesUrl = base_url + "/getProfReclaimableCodes";
export const getNamedProfListUrl = base_url + "/searchProfByName";

// Activation codes
export const reclaimAllCodesUrl = base_url + "/reclaimAllCodes";

// Classes routes
export const fetchClassroomsUrl = base_url + "/class/getAllProClassRooms";
export const addClassroomUrl = base_url + "/class/createClassRoom";
export const addProflessClassroomUrl = base_url + "/class/createProflessClassRoom";
export const modifyClassroomUrl = base_url + "/class/modifyClassroom";