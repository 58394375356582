import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
    addProfUrl, assignCodesToProfUrl, getNamedProfListUrl, getNumberOfReclaimableCodesUrl,
    getProfsUrl, getSingleProfUrl, reclaimAllProfsCodesUrl, reclaimSingleProfCodesUrl, suspendProfUrl,
    updateProfUrl,
} from "../../constants";
import { message } from "antd";

export const profsSlice = createSlice({
    name: "profs",
    initialState: {
        isFetchingProfs: false,
        profList: [],
        profError: null,

        isFetchingSingleProf: false,
        singleProfResult: null,
        singleProfError: null,

        addProfResult: null,
        addProfError: null,
        isAddingProf: false,

        updateProfResult: null,
        updateProfError: null,
        isUpdatingProf: false,

        assignProfResult: null,
        assignProfError: null,
        isAssigningProf: false,

        suspendProfResult: null,
        suspendProfError: null,
        isSuspendingProf: false,

        reclaimSingleProfCodesResult: null,
        reclaimSingleProfCodesError: null,
        isReclaimingSingleProfCodes: false,

        reclaimAllProfsCodesResult: null,
        reclaimAllProfsCodesError: null,
        isReclaimingAllProfsCodes: false,

        numberOfReclaimableCodes: null,
        numberOfReclaimableCodesError: null,
        isFetchingNReclaimableCodes: false,

        namedProfsList: null,
        namedProfsListError: null,
        isFetchingNamedProfsList: false,
    },
    reducers: {
        setAddProfResult: (state, action) => {
            state.addProfResult = action.payload;
            state.isAddingProf = false;
        },
        setAddProfError: (state, action) => {
            state.addProfError = action.payload;
            state.isAddingProf = false;
        },
        setIsAddingProf: (state, action) => {
            state.isAddingProf = action.payload;
        },

        setUpdateProfResult: (state, action) => {
            state.updateProfResult = action.payload;
            state.isUpdatingProf = false;
        },
        setUpdateProfError: (state, action) => {
            state.updateProfError = action.payload;
            state.isUpdatingProf = false;
        },
        setIsUpdatingProf: (state, action) => {
            state.isUpdatingProf = action.payload;
        },

        setAssignProfResult: (state, action) => {
            state.assignProfResult = action.payload;
            state.isAssigningProf = false;
        },
        setAssignProfError: (state, action) => {
            state.assignProfError = action.payload;
            state.isAssigningProf = false;
        },
        setIsAssigningProf: (state, action) => {
            state.isAssigningProf = action.payload;
        },

        setFetchingProfs: (state, action) => {
            state.isFetchingProfs = action.payload;
        },
        setProfsResult: (state, action) => {
            state.profList = action.payload;
            state.isFetchingProfs = false;
        },
        setProfsError: (state, action) => {
            state.profError = action.payload;
            state.isFetchingProfs = false;
        },

        setFetchingSingleProf: (state, action) => {
            state.isFetchingSingleProf = action.payload;
        },
        setSingleProfResult: (state, action) => {
            state.singleProfResult = action.payload;
            state.isFetchingSingleProf = false;
        },
        setSingleProfError: (state, action) => {
            state.singleProfError = action.payload;
            state.isFetchingSingleProf = false;
        },

        setSuspendProfResult: (state, action) => {
            state.suspendProfResult = action.payload;
            state.isSuspendingProf = false;
        },
        setSuspendProfError: (state, action) => {
            state.suspendProfError = action.payload;
            state.isSuspendingProf = false;
        },
        setIsSuspendingProf: (state, action) => {
            state.isSuspendingProf = action.payload;
        },

        setIsReclaimingAllProfsCodes: (state, action) => {
            state.isReclaimingAllProfsCodes = action.payload;
        },
        setReclaimAllProfsCodesResult: (state, action) => {
            state.reclaimAllProfsCodesResult = action.payload;
            state.reclaimAllProfsCodesError = null;
            state.isReclaimingAllProfsCodes = false;
        },
        setReclaimAllProfsCodesError: (state, action) => {
            state.reclaimAllProfsCodesError = action.payload;
            state.reclaimAllProfsCodesResult = null;
            state.isReclaimingAllProfsCodes = false;
        },

        setIsReclaimingSingleProfCodes: (state, action) => {
            state.isReclaimingSingleProfCodes = action.payload;
        },
        setReclaimSingleProfCodesResult: (state, action) => {
            state.reclaimSingleProfCodesResult = action.payload;
            state.reclaimSingleProfCodesError = null;
            state.isReclaimingSingleProfCodes = false;
        },
        setReclaimSingleProfCodesError: (state, action) => {
            state.reclaimSingleProfCodesError = action.payload;
            state.reclaimSingleProfCodesResult = null;
            state.isReclaimingSingleProfCodes = false;
        },

        setNumberOfReclaimableCodes: (state, action) => {
            state.numberOfReclaimableCodes = action.payload;
            state.numberOfReclaimableCodesError = null;
            state.isFetchingNReclaimableCodes = false;
        },
        setNumberOfReclaimableCodesError: (state, action) => {
            state.numberOfReclaimableCodesError = action.payload;
            state.numberOfReclaimableCodes = null;
            state.isFetchingNReclaimableCodes = false;
        },
        setIsFetchingNReclaimableCodes: (state, action) => {
            state.isFetchingNReclaimableCodes = action.payload;
        },

        setNamedProfsList: (state, action) => {
            state.namedProfsList = action.payload;
            state.namedProfsListError = null;
            state.isFetchingNamedProfsList = false;
        },
        setNamedProfsListError: (state, action) => {
            state.namedProfsListError = action.payload;
            state.namedProfsList = null;
            state.isFetchingNamedProfsList = false;
        },
        setIsFetchingNamedProfsList: (state, action) => {
            state.isFetchingNamedProfsList = action.payload;
        }

    },
});

export const {
    setAddProfResult,
    setAddProfError,
    setIsAddingProf,

    setUpdateProfResult,
    setUpdateProfError,
    setIsUpdatingProf,

    setAssignProfResult,
    setAssignProfError,
    setIsAssigningProf,

    setFetchingProfs,
    setProfsResult,
    setProfError,

    setFetchingSingleProf,
    setSingleProfResult,
    setSingleProfError,

    setSuspendProfResult,
    setSuspendProfError,
    setIsSuspendingProf,

    setIsReclaimingAllProfsCodes,
    setReclaimAllProfsCodesResult,
    setReclaimAllProfsCodesError,

    setNumberOfReclaimableCodes,
    setNumberOfReclaimableCodesError,
    setIsFetchingNReclaimableCodes,

    setIsReclaimingSingleProfCodes,
    setReclaimSingleProfCodesResult,
    setReclaimSingleProfCodesError,

    setNamedProfsList,
    setNamedProfsListError,
    setIsFetchingNamedProfsList,

} = profsSlice.actions;

export const addProf = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: addProfUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setIsAddingProf(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setAddProfResult(response.data));
        })
        .catch((response) => {
            message.error(response.response.data.message);
            dispatch(setAddProfError(response.message));
        });


    return response.data;
};

export const updateProf = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: updateProfUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setIsUpdatingProf(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setUpdateProfResult(response.data));
        })
        .catch((response) => {
            message.error(response.response.data.message);
            dispatch(setUpdateProfError(response.message));
        });


    return response.data;
};

export const assignCodes = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: assignCodesToProfUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setIsAssigningProf(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setAssignProfResult(response.data));
        })
        .catch((response) => {
            message.error(response.response.data.message);
            dispatch(setAssignProfError(response.message));
        });


    return response.data;
};

export const fetchSingleProf = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getSingleProfUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setFetchingSingleProf(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setSingleProfResult(response.data?.prof));
        })
        .catch((response) => {
            message.error(response.message);
            dispatch(setSingleProfError(response.message));
        });


    return response.data;
};

export const fetchProfs = (cookies) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getProfsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
    };

    dispatch(setFetchingProfs(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setProfsResult(response.data?.profList));
        })
        .catch((response) => {
            message.error(response.message);
            dispatch(setProfError(response.message));
        });


    return response.data;
};

export const fetchNamedProfs = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getNamedProfListUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setIsFetchingNamedProfsList(true));
    const response = axios(config)
        .then((response) => {
            let proflist = response.data?.profList;
            proflist = proflist.map((prof) => { return { value: prof._id, label: prof.name } })
            dispatch(setNamedProfsList(proflist));
        })
        .catch((response) => {
            message.error(response.message);
            dispatch(setNamedProfsListError(response.message));
        });


    return response.data;
};

export const suspendProf = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: suspendProfUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setIsSuspendingProf(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setSuspendProfResult(response.data));
        })
        .catch((response) => {
            message.error(response.response.data.message);
            dispatch(setSuspendProfError(response.message));
        });


    return response.data;
};

export const reclaimAllProfsCodes = (cookies) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: reclaimAllProfsCodesUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
    };

    dispatch(setIsReclaimingAllProfsCodes(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setReclaimAllProfsCodesResult(response.data?.numberOfCodes));
        })
        .catch((response) => {
            message.error(response.response.data.message);
            dispatch(setReclaimAllProfsCodesError(response.message));
        });


    return response.data;
};

export const getNumberOfReclaimableCodes = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getNumberOfReclaimableCodesUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setIsFetchingNReclaimableCodes(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setNumberOfReclaimableCodes(response.data?.numberOfCodes));
        })
        .catch((response) => {
            message.error(response.response.data.message);
            dispatch(setNumberOfReclaimableCodesError(response.message));
        });


    return response.data;
};

export const reclaimSingleProfCodes = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: reclaimSingleProfCodesUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setIsReclaimingSingleProfCodes(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setReclaimSingleProfCodesResult(response.data?.message));
        })
        .catch((response) => {
            message.error(response.response.data.message);
            dispatch(setReclaimSingleProfCodesError(response.message));
        });


    return response.data;
};

export default profsSlice.reducer;
