import { Button, Drawer, Form, Input, Space, Spin, message } from "antd"
import {
    DeleteOutlined
} from "@ant-design/icons"
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfs, fetchSingleProf, setUpdateProfResult, updateProf } from "../features/prof/profsSlice";
import { addProflessClassroom, setAddProflessClassroom } from "../features/classrooms/classroomsSlice";

export default function EditProfForm({ open, setEditDrawerOpen, id }) {
    const [cookies] = useCookies();
    const dispatch = useDispatch();

    const formRef = useRef(null);

    const proflessClassResult = useSelector((state) => state.classrooms.addProflessClassroom);

    const [formData, setFormData] = useState({
        id: null,
        name: "",
        phoneNumber: "",
        email: "",
        password: "",
        classIds: []
    });

    const [classes, setClasses] = useState([]);
    const [classNameToBeAdded, setClassNameToBeAdded] = useState("");

    useEffect(() => {
        setClassNameToBeAdded("");
        setClasses([]);
        setFormData({ ...formData, classIds: [] });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchSingleProf(cookies, { id }));
        setFormData({
            id: id,
            name: "",
            phoneNumber: "",
            email: "",
            password: "",
            classIds: []
        });
        // Sneaky hack, because we have to wait for formData to be updated before we reset the form.
        setTimeout(() => { formRef?.current.resetFields() }, 300);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const singleProfResult = useSelector((state) => state.profs.singleProfResult);
    const updateProfResult = useSelector((state) => state.profs.updateProfResult);
    const isUpdatingProf = useSelector((state) => state.profs.isUpdatingProf);

    const addClassToList = (classroom) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            classIds: [...prevFormData.classIds, classroom._id],
        }));
        setClasses([...classes, classroom]);
        setClassNameToBeAdded("");
    }

    const removeClassFromList = (classroom) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            classIds: prevFormData.classIds.filter(classId => classId !== classroom._id),
        }));
        setClasses(classes.filter(localClassroom => localClassroom._id !== classroom._id));
    }

    useEffect(() => {
        if (!proflessClassResult) {
            return;
        }
        addClassToList(proflessClassResult);
        dispatch(setAddProflessClassroom(null));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proflessClassResult])

    useEffect(() => {
        if (!updateProfResult) return;
        message.success(updateProfResult?.message);
        dispatch(setUpdateProfResult(null));
        dispatch(fetchProfs(cookies));

        setFormData({
            ...formData,
            name: "",
            phoneNumber: "",
            email: "",
            password: "",
            classIds: []
        });
        // Sneaky hack, because we have to wait for formData to be updated before we reset the form.
        setTimeout(() => { formRef?.current.resetFields() }, 300);

        setEditDrawerOpen(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateProfResult]);

    const onClose = () => {
        setEditDrawerOpen(false);
    };

    const UpdateUser = () => {
        dispatch(updateProf(cookies, formData));
    }

    return (
        <Drawer
            title="Modifier un prof"
            width={720}
            open={open}
            onClose={onClose}
            extra={
                <Space>
                    <Button onClick={onClose}>Annuler</Button>
                    <Button onClick={UpdateUser} type="primary">
                        {isUpdatingProf ? <Spin /> : "Sauvegarder"}
                    </Button>
                </Space>
            }
        >
            <Form
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
                name="notExistsForm"
                layout="vertical"
                initialValues={formData}
                ref={formRef}
            >
                <div style={{ display: "flex", width: "40%", flexDirection: "column" }}>
                    <Form.Item
                        label="Nom du prof"
                        name="name"
                        rules={[
                            { required: false, message: 'Veuillez insérer un nom.' }
                        ]}
                    >
                        <Input
                            defaultValue=''
                            placeholder={singleProfResult?.name}
                            value={formData.name}
                            onChange={(e) => { setFormData({ ...formData, name: e.target.value }) }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Adresse mail"
                        name="email"
                        rules={[
                            { required: false, message: 'Veuillez insérer un email.' }
                        ]}
                    >
                        <Input
                            placeholder={singleProfResult?.email}
                            value={formData.email}
                            onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Nombre d'accès attribués"
                        name="nAccessSeats"
                        rules={[
                            { required: false, message: 'Veuillez insérer un email.' }
                        ]}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                            <Input
                                disabled={true}
                                max={Number.MAX_VALUE}
                                min={1}
                                value={singleProfResult?.numberOfActivationCodes}
                            />
                        </div>
                    </Form.Item>
                    {/* TODO: Create a list once the class CRUD is made */}
                    <Form.Item
                        label="Classes"
                        name="classes"
                        rules={[
                            { required: false, message: 'Veuillez insérer un email.' }
                        ]}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            {classes.map((classroomData, index) => (
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: "8px"
                                }}>
                                    <Input disabled={true} key={index} value={classroomData.name}></Input>
                                    <Button
                                        className="buttonStyle"
                                        style={{ width: "4vw", marginLeft: "8px", backgroundColor: "#FF4D4F" }}
                                        onClick={() => removeClassFromList(classroomData)}
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                </div>
                            ))}
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                            <Input
                                placeholder="Nom"
                                value={classNameToBeAdded}
                                onChange={(e) => setClassNameToBeAdded(e.target.value)}
                            />
                            <Button
                                className="buttonStyle"
                                style={{ width: "4vw", marginLeft: "8px" }}
                                onClick={() => dispatch(addProflessClassroom(cookies, { className: classNameToBeAdded }))}
                            >
                                Ajouter
                            </Button>
                        </div>
                    </Form.Item>
                </div>
                <div style={{ display: "flex", width: "40%", flexDirection: "column" }}>
                    <Form.Item
                        label="Numéro de téléphone"
                        name="phone"
                        rules={[
                            { required: false, message: 'Veuillez insérer un numéro de téléphone.' }
                        ]}
                    >
                        <Input
                            placeholder={singleProfResult?.phoneNumber}
                            value={formData.phoneNumber}
                            onChange={(e) => { setFormData({ ...formData, phoneNumber: e.target.value }) }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Mot de passe"
                        name="password"
                        rules={[
                            { required: false, message: 'Veuillez insérer un mot de passe.' }
                        ]}
                    >
                        <Input.Password
                            value={formData.password}
                            onChange={(e) => { setFormData({ ...formData, password: e.target.value }) }}
                        />
                    </Form.Item>
                    <div style={{ justifyContent: "start" }}>
                        <p style={{ color: "#00000073" }}>Le Mot de Passe doit comporter au moins :</p>
                        <ul style={{ paddingLeft: "16px", listStyle: "none" }}>
                            <li style={{ color: "#00000073" }}>De 8 à 12 caractères</li>
                            <li style={{ color: "#00000073" }}>Au moins 1 minuscule</li>
                            <li style={{ color: "#00000073" }}>Au moins 1 majuscule</li>
                            <li style={{ color: "#00000073" }}>Au moins 1 chiffre</li>
                        </ul>
                    </div>
                </div>
            </Form>
        </Drawer>
    )
}