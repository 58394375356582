import { Button, Form, Input, InputNumber, Modal, Radio, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { addProf, assignCodes, setAddProfResult, setAssignProfResult } from "../features/prof/profsSlice";
import {
    UserOutlined, DeleteOutlined
} from '@ant-design/icons';
import { addProflessClassroom, setAddProflessClassroom } from "../features/classrooms/classroomsSlice";

export const assignmentMode = {
    ProfExists: "ProfExists",
    ProfDoesntExist: "ProfDoesntExist"
};

/**
 * This component can render two different forms based on the mode.
 * Basically, if the client wants to assign codes to an existing prof,
 * we should render a form with only the email and the number of codes to be assigned.
 * If the client wants to assign and create a prof, we need to send a full
 * sign up form.
 * So this component renders two subcomponents conditionally.
 */
const AssignCodesToProf = ({ isModalOpen, handleCancel, mode, setAssignMode }) => {
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookies, removeCookie] = useCookies();

    const proflessClassResult = useSelector((state) => state.classrooms.addProflessClassroom);

    const [classes, setClasses] = useState([]);
    const [classNameToBeAdded, setClassNameToBeAdded] = useState("");

    const [existsFormData, setExistsFormData] = useState({
        email: "",
        numberOfCodes: 1
    });

    const [notExistsFormData, setNotExistsFormData] = useState({
        name: "",
        phoneNumber: "",
        email: "",
        password: "",
        nAccessSeats: 1,
        classIds: []
    });

    const isAttributingCodes = useSelector((state) => state.profs.isAssigningProf);
    const isAddingProf = useSelector((state) => state.profs.isAddingProf);
    const assigningResult = useSelector((state) => state.profs.assignProfResult);
    const addProfResult = useSelector((state) => state.profs.addProfResult);

    const addClassToList = (classroom) => {
        setNotExistsFormData((prevFormData) => ({
            ...prevFormData,
            classIds: [...prevFormData.classIds, classroom._id],
        }));
        setClasses([...classes, classroom]);
        setClassNameToBeAdded("");
    }

    const removeClassFromList = (classroom) => {
        setNotExistsFormData((prevFormData) => ({
            ...prevFormData,
            classIds: prevFormData.classIds.filter(classId => classId !== classroom._id),
        }));
        setClasses(classes.filter(localClassroom => localClassroom._id !== classroom._id));
    }

    useEffect(() => {
        setClassNameToBeAdded("");
        setClasses([]);
        setNotExistsFormData({ ...notExistsFormData, classIds: [] });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!proflessClassResult) {
            return;
        }
        addClassToList(proflessClassResult);
        dispatch(setAddProflessClassroom(null));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proflessClassResult])

    useEffect(() => {
        if (!assigningResult) {
            return;
        }
        message.success(assigningResult.message);
        handleCancel();
        dispatch(setAssignProfResult(null));


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assigningResult]);

    useEffect(() => {
        if (!addProfResult) {
            return;
        }
        message.success(addProfResult.message);
        handleCancel();
        dispatch(setAddProfResult(null));
        window.location.reload(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addProfResult])

    const handleAssign = () => {
        if (mode === assignmentMode.ProfExists) {
            if (existsFormData.email === "") {
                return message.error("Le champs email est vide.");
            }
            if (existsFormData.numberOfCodes < 1) {
                return message.error("Le nombre de code est inférieur à 1.");
            }
            dispatch(assignCodes(cookies, existsFormData));
        }

        else if (mode === assignmentMode.ProfDoesntExist) {
            // Validation is hard haha
            // if I ever come back here, I'm refactoring it and using JOI
            if (!notExistsFormData.name) {
                return message.error("Il manque le nom.");
            }
            if (!notExistsFormData.email) {
                return message.error("Il manque l'email.");
            }
            if (!notExistsFormData.password) {
                return message.error("Il manque le mot de passe.");
            }
            if (!notExistsFormData.phoneNumber) {
                return message.error("Il manque le numéro de téléphone.");
            }
            if (notExistsFormData.nAccessSeats < 1) {
                return message.error("Le nombre de code est inférieur à 1.");
            }

            dispatch(addProf(cookies, notExistsFormData));
        }
    }

    const renderForm = () => {
        switch (mode) {
            case assignmentMode.ProfExists:
                return <ExistsForm formData={existsFormData} setExistsFormData={setExistsFormData} setAssignMode={setAssignMode} />;
            case assignmentMode.ProfDoesntExist:
                return <NotExistsForm
                    formData={notExistsFormData}
                    setNotExistsFormData={setNotExistsFormData}
                    setAssignMode={setAssignMode}
                    classes={classes}
                    setClasses={setClasses}
                    addClassToList={addClassToList}
                    removeClassFromList={removeClassFromList}
                    classNameToBeAdded={classNameToBeAdded}
                    setClassNameToBeAdded={setClassNameToBeAdded}

                />;
            default:
                return null;
        }
    }

    return (
        <Modal
            title="Attribuer des codes à un prof"
            open={isModalOpen}
            okText={(isAttributingCodes || isAddingProf) ? <Spin /> : "Attribuer"}
            onOk={handleAssign}
            onCancel={handleCancel}
        >
            {renderForm()}

        </Modal>
    );
};

const ExistsForm = ({ formData, setExistsFormData, setAssignMode }) => {
    return (
        <Form
            name="existsForm"
            initialValues={formData}
            onFinish={(val) => console.log(val)}
        >
            <Form.Item label="Compte prof">
                <Radio.Group value={assignmentMode.ProfExists}>
                    <Radio value={assignmentMode.ProfExists}> Existant </Radio>
                    <Radio value={assignmentMode.ProfDoesntExist} onClick={() => setAssignMode(assignmentMode.ProfDoesntExist)}> Non existant </Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label="Adresse mail"
                name="email"
                rules={[
                    { required: true, message: 'Veuillez insérer un email.' }
                ]}
            >
                <Input
                    placeholder="prof.adresse@établissement.com"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    value={formData.email}
                    onChange={(e) => { setExistsFormData({ ...formData, email: e.target.value }) }}
                />
            </Form.Item>
            <Form.Item
                label="Envoyer"
                name="numberOfCodes"
                rules={[
                    { required: true, message: 'Veuillez insérer un email.' }
                ]}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "8px"
                }}>
                    <InputNumber
                        // style={{
                        //     width: 70
                        // }}
                        max={Number.MAX_VALUE}
                        min={1}
                        value={formData.numberOfCodes}
                        onChange={(e) => { setExistsFormData({ ...formData, numberOfCodes: e }) }}
                    />
                    <p style={{ marginLeft: "8px" }}>Codes</p>
                </div>

            </Form.Item>


        </Form>
    );
}

const NotExistsForm = ({ formData, setNotExistsFormData, setAssignMode, classes, setClasses, addClassToList, removeClassFromList, classNameToBeAdded, setClassNameToBeAdded, }) => {
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookies, removeCookie] = useCookies();

    return (
        <Form
            name="notExistsForm"
            initialValues={formData}
        >
            <Form.Item label="Compte prof">
                <Radio.Group value={assignmentMode.ProfDoesntExist}>
                    <Radio value={assignmentMode.ProfExists} onClick={() => setAssignMode(assignmentMode.ProfExists)}> Existant </Radio>
                    <Radio value={assignmentMode.ProfDoesntExist}> Non existant </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Nom du prof"
                name="name"
                rules={[
                    { required: true, message: 'Veuillez insérer un nom.' }
                ]}
            >
                <Input
                    placeholder="Prénom NOM"
                    value={formData.name}
                    onChange={(e) => { setNotExistsFormData({ ...formData, name: e.target.value }) }}
                />
            </Form.Item>
            <Form.Item
                label="Numéro de téléphone"
                name="phone"
                rules={[
                    { required: true, message: 'Veuillez insérer un numéro de téléphone.' }
                ]}
            >
                <Input
                    placeholder="+33 XXX - XXX - XXX"
                    value={formData.phoneNumber}
                    onChange={(e) => { setNotExistsFormData({ ...formData, phoneNumber: e.target.value }) }}
                />
            </Form.Item>
            <Form.Item
                label="Adresse mail"
                name="email"
                rules={[
                    { required: true, message: 'Veuillez insérer un email.' }
                ]}
            >
                <Input
                    placeholder="prof.adresse@établissement.com"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    value={formData.email}
                    onChange={(e) => { setNotExistsFormData({ ...formData, email: e.target.value }) }}
                />
            </Form.Item>
            <Form.Item
                label="Mot de passe"
                name="password"
                rules={[
                    { required: true, message: 'Veuillez insérer un mot de passe.' }
                ]}
            >
                <Input.Password
                    value={formData.password}
                    onChange={(e) => { setNotExistsFormData({ ...formData, password: e.target.value }) }}
                />
            </Form.Item>
            <Form.Item
                label="Envoyer"
                name="nAccessSeats"
                rules={[
                    { required: true, message: 'Veuillez insérer un email.' }
                ]}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                    <InputNumber
                        max={Number.MAX_VALUE}
                        min={1}
                        value={formData.nAccessSeats}
                        onChange={(e) => { setNotExistsFormData({ ...formData, nAccessSeats: e }) }}
                    />
                    <p style={{ marginLeft: "8px" }}>Codes</p>
                </div>
            </Form.Item>

            {/* TODO: Create a list once the class CRUD is made */}
            <Form.Item
                label="Classes"
                name="classes"
                rules={[
                    { required: false, message: 'Veuillez insérer un email.' }
                ]}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",

                }}>
                    {classes.map((classroomData, index) => (
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "8px",
                            width: "100%"
                        }}>
                            <Input disabled={true} key={index} value={classroomData.name}></Input>
                            <Button
                                className="buttonStyle"
                                style={{ width: "4vw", marginLeft: "8px", backgroundColor: "#FF4D4F" }}
                                onClick={() => removeClassFromList(classroomData)}
                            >
                                <DeleteOutlined />
                            </Button>
                        </div>
                    ))}
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                    <Input
                        placeholder="Nom"
                        value={classNameToBeAdded}
                        onChange={(e) => setClassNameToBeAdded(e.target.value)}
                    />
                    <Button
                        className="buttonStyle"
                        style={{ width: "4vw", marginLeft: "8px" }}
                        onClick={() => dispatch(addProflessClassroom(cookies, { className: classNameToBeAdded }))}
                    >
                        Ajouter
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
}

export default AssignCodesToProf;