import { configureStore } from '@reduxjs/toolkit';
import SignInReducer from '../features/signIn/signInSlice';
import codesReducer from '../features/activation/codesSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import profsReducer from '../features/prof/profsSlice';
import classroomsReducer from '../features/classrooms/classroomsSlice';


export const store = configureStore({
  reducer: {
    signIn: SignInReducer,
    codes: codesReducer,
    dashboard: dashboardReducer,
    profs: profsReducer,
    classrooms: classroomsReducer,
  },
});
