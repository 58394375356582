module.exports = {
    /**
     * Made to filter through menu items, so the user can't see the menu items
     * related to profs if they don't need to.
     * @param {Array} menuItems A menu object found in the dashboard.js feature 
     * @returns Same list, but without items with the 'alwaysVisible' property set to false
     */
    filterAlwaysVisible: (menuItems) => {
        let filteredItems = {};
        for (const key in menuItems) {
            if (Array.isArray(menuItems[key])) {
                filteredItems[key] = menuItems[key].filter(item => item.alwaysVisible);
            }
        }
        return filteredItems;
    },
}