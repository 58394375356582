import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import Signin from './features/signIn/signIn';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import reportWebVitals from './reportWebVitals';
import './index.css';
import Dashboard from './features/dashboard/dashboard';
import ActivationCodes from './features/activation/ActivationCodes';
import ProfList from './features/prof/profList';
import AddProf from './features/prof/addProf';
import ClassroomList from './features/classrooms/classroomList';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/signin" element={<Signin />} />
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="codes">
                <Route path="codeList" element={<ActivationCodes />} />
              </Route>
              <Route path="profs">
                <Route path="profList" element={<ProfList />} />
                <Route path="profList/create" element={<AddProf />} />
              </Route>
              <Route path="classes">
                <Route path="classList" element={<ClassroomList />} />
              </Route>
            </Route>
            <Route exact path="/" element={<Signin />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
