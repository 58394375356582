import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { ExportPDFUrl, getActivationLinksUrl, getCodesCountUrl, getCodesUrl, getModulesExportOptionsUrl, reclaimAllCodesUrl, sendActivationCodesEmailUrl } from "../../constants";

export const codesSlice = createSlice({
    name: "codes",
    initialState: {
        fetchCodesResult: null,
        fetchCodesError: null,
        isFetchingCodes: false,
        fetchCodeCountsResult: null,
        fetchCodeCountsError: null,
        isFetchingCodeCounts: false,
        fetchModuleOptionsResult: null,
        fetchModuleOptionsFailure: null,
        isFetchingModuleOptions: false,
        exportPDFResult: null,
        exportPDFFailure: null,
        isExportingPDF: false,
        activationLinksResult: null,
        activationLinksFailure: null,
        isFetchingActivationLinks: false,
        sendEmailsResult: null,
        sendEmailsFailure: null,
        isFetchingSendEmails: false,
        // Reclaiming codes
        reclaimCodesResult: null,
        reclaimCodesError: null,
        isReclaimingCodes: false,
    },
    reducers: {
        fetchCodesStart: (state) => {
            state.isFetchingCodes = true;
            state.fetchCodesResult = null;
            state.fetchCodesError = null;
        },
        fetchCodesSuccess: (state, action) => {
            state.isFetchingCodes = false;
            state.fetchCodesResult = action.payload;
            state.fetchCodesError = null;
        },
        fetchCodesFailure: (state, action) => {
            state.isFetchingCodes = false;
            state.fetchCodesResult = null;
            state.fetchCodesError = action.payload;
        },
        fetchCodeCountsRequest: (state) => {
            state.isFetchingCodeCounts = true;
            state.fetchCodeCountsError = null;
            state.fetchCodeCountsResult = null;
        },
        // Reducer for setting the fetched code counts and updating the state
        fetchCodeCountsSuccess: (state, action) => {
            state.isFetchingCodeCounts = false;
            state.fetchCodeCountsError = null;
            state.fetchCodeCountsResult = action.payload;
        },
        // Reducer for setting the error while fetching code counts
        fetchCodeCountsFailure: (state, action) => {
            state.isFetchingCodeCounts = false;
            state.fetchCodeCountsError = action.payload;
            state.fetchCodeCountsResult = null;
        },
        fetchModuleOptionsRequest: (state) => {
            state.isFetchingModuleOptions = true;
            state.fetchModuleOptionsResult = null;
            state.fetchModuleOptionsFailure = null;
        },
        fetchModuleOptionsSuccess: (state, action) => {
            state.isFetchingModuleOptions = false;
            state.fetchModuleOptionsResult = action.payload;
            state.fetchModuleOptionsFailure = null;
        },
        fetchModuleOptionsFailure: (state, action) => {
            state.isFetchingModuleOptions = false;
            state.fetchModuleOptionsFailure = action.payload;
            state.fetchModuleOptionsResult = null;
        },
        exportPDFRequest: (state) => {
            state.isExportingPDF = true;
            state.exportPDFResult = null;
            state.exportPDFFailure = null;
        },
        exportPDFSuccess: (state, action) => {
            state.isExportingPDF = false;
            state.exportPDFResult = action.payload;
            state.exportPDFFailure = null;
        },
        exportPDFFailure: (state, action) => {
            state.isExportingPDF = false;
            state.exportPDFFailure = action.payload;
            state.exportPDFResult = null;
        },
        activationLinksRequest: (state) => {
            state.isFetchingActivationLinks = true;
            state.activationLinksResult = null;
            state.activationLinksFailure = null;
        },
        activationLinksSuccess: (state, action) => {
            state.isFetchingActivationLinks = false;
            state.activationLinksResult = action.payload;
            state.activationLinksFailure = null;
        },
        activationLinksFailure: (state, action) => {
            state.isFetchingActivationLinks = false;
            state.activationLinksFailure = action.payload;
            state.activationLinksResult = null;
        },
        sendEmailsRequest: (state) => {
            state.isFetchingSendEmails = true;
            state.sendEmailsResult = null;
            state.sendEmailsFailure = null;
        },
        sendEmailsSuccess: (state, action) => {
            state.isFetchingSendEmails = false;
            state.sendEmailsResult = action.payload;
            state.sendEmailsFailure = null;
        },
        sendEmailsFailure: (state, action) => {
            state.isFetchingSendEmails = false;
            state.sendEmailsFailure = action.payload;
            state.sendEmailsResult = null;
        },
        // Reclaiming codes
        setReclaimCodesResult: (state, action) => {
            state.isReclaimingCodes = false;
            state.reclaimCodesResult = action.payload;
            state.reclaimCodesError = null;
        },
        setReclaimCodesError: (state, action) => {
            state.isReclaimingCodes = false;
            state.reclaimCodesError = action.payload;
            state.reclaimCodesResult = null;
        },
        setIsReclaimingCodes: (state, action) => {
            state.isReclaimingCodes = action.payload;
        }

    },

});

export const {
    fetchCodesStart,
    fetchCodesSuccess,
    fetchCodesFailure,
    fetchCodeCountsRequest,
    fetchCodeCountsSuccess,
    fetchCodeCountsFailure,
    fetchModuleOptionsRequest,
    fetchModuleOptionsSuccess,
    fetchModuleOptionsFailure,
    exportPDFRequest,
    exportPDFSuccess,
    exportPDFFailure,
    activationLinksRequest,
    activationLinksSuccess,
    activationLinksFailure,
    sendEmailsRequest,
    sendEmailsSuccess,
    sendEmailsFailure,
    // Reclaim codes
    setReclaimCodesResult,
    setReclaimCodesError,
    setIsReclaimingCodes,

} = codesSlice.actions;

export const getCodesCount = (cookies) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getCodesCountUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token
        },
    };
    dispatch(fetchCodeCountsRequest());
    axios(config)
        .then((response) => {
            dispatch(fetchCodeCountsSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                message.error("Le serveur ne répond pas, veuillez réessayer plus tard.");
                dispatch(fetchCodeCountsFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(fetchCodeCountsFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(fetchCodeCountsFailure(error.response.data.message));
        });
}

export const getCodes = (cookies, filters) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getCodesUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token
        },
        data: filters
    };

    dispatch(fetchCodesStart());
    axios(config)
        .then((response) => {
            dispatch(fetchCodesSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                message.error("Le serveur ne répond pas, veuillez réessayer plus tard.");
                dispatch(fetchCodesFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(fetchCodesFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(fetchCodesFailure(error.response.data.message));
        });
}

export const getModulesExportOptions = (cookies) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getModulesExportOptionsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token
        },
    };

    dispatch(fetchModuleOptionsRequest());
    axios(config)
        .then((response) => {
            dispatch(fetchModuleOptionsSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                message.error("Le serveur ne répond pas, veuillez réessayer plus tard.");
                dispatch(fetchModuleOptionsFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(fetchModuleOptionsFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(fetchModuleOptionsFailure(error.response.data.message));
        });

}
export const getActivationLinks = (cookies) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: getActivationLinksUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token
        },
    };

    dispatch(activationLinksRequest());
    axios(config)
        .then((response) => {
            dispatch(activationLinksSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                message.error("Le serveur ne répond pas, veuillez réessayer plus tard.");
                dispatch(activationLinksFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(activationLinksFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(activationLinksFailure(error.response.data.message));
        });

}

export const ExportPDF = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: ExportPDFUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token
        },
        data
    };
    dispatch(exportPDFRequest());
    axios(config)
        .then((response) => {
            dispatch(exportPDFSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                message.error("Le serveur ne répond pas, veuillez réessayer plus tard.");
                dispatch(exportPDFFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(exportPDFFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(exportPDFFailure(error.response.data.message));
        });
}

export const sendActivationCodesEmail = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: sendActivationCodesEmailUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token
        },
        data
    };
    dispatch(sendEmailsRequest());
    axios(config)
        .then((response) => {
            dispatch(sendEmailsSuccess(response.data));
        })
        .catch((error) => {
            if (!error.response) {
                message.error("Le serveur ne répond pas, veuillez réessayer plus tard.");
                dispatch(sendEmailsFailure("Le serveur ne répond pas, veuillez réessayer plus tard."))
                return;
            }
            if (error.response.status === 401) {
                message.error("Vous êtes déconnecté, veuillez vous reconnecter.");
                dispatch(sendEmailsFailure("Vous n'êtes pas autorisé à faire cette action."));
                return;
            }
            message.error(error.response.data.message);
            dispatch(sendEmailsFailure(error.response.data.message));
        });
}

export const reclaimAllCodes = (cookies) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: reclaimAllCodesUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
    };

    dispatch(setIsReclaimingCodes(true));
    const response = axios(config)
        .then((response) => {
            const resultMessage = response.data.message;
            message.success(resultMessage);
            dispatch(setReclaimCodesResult(response.data));
        })
        .catch((response) => {
            message.error(response.response.data.message);
            dispatch(setReclaimCodesError(response.message));
        });


    return response.data;
};

export default codesSlice.reducer;