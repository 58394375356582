import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import { addClassroomUrl, addProflessClassroomUrl, fetchClassroomsUrl, modifyClassroomUrl } from "../../constants";

export const classroomsSlice = createSlice({
    name: "classrooms",
    initialState: {
        classroomList: [],
        classroomListError: null,
        isFetchingClassroomList: false,
        totalClassrooms: null,

        addClassroomResult: null,
        addClassroomError: null,
        isAddingClassroom: false,

        addProflessClassroom: null,
        addProflessClassroomError: null,
        isAddingProflessClassroom: false,

        modifyClassroomResult: null,
        modifyClassroomError: null,
        isModifyingClassroom: false,

    },
    reducers: {
        setClassroomList: (state, action) => {
            state.classroomList = action.payload;
            state.classroomListError = null;
            state.isFetchingClassroomList = false;
        },
        setClassroomListError: (state, action) => {
            state.classroomListError = action.payload;
            state.classroomList = [];
            state.isFetchingClassroomList = false;
        },
        setIsFetchingClassroomList: (state, action) => {
            state.isFetchingClassroomList = action.payload;
        },
        setTotalClassrooms: (state, action) => {
            state.totalClassrooms = action.payload;
        },

        setAddClassroom: (state, action) => {
            state.addClassroomResult = action.payload;
            state.addClassroomError = null;
            state.isAddingClassroom = false;
        },
        setAddClassroomError: (state, action) => {
            state.addClassroomError = action.payload;
            state.addClassroomResult = null;
            state.isAddingClassroom = false;
        },
        setIsAddingClassroom: (state, action) => {
            state.isAddingClassroom = action.payload;
        },

        setAddProflessClassroom: (state, action) => {
            state.addProflessClassroom = action.payload;
            state.addProflessClassroomError = null;
            state.isAddingProflessClassroom = false;
        },
        setAddProflessClassroomError: (state, action) => {
            state.addProflessClassroomError = action.payload;
            state.addProflessClassroom = null;
            state.isAddingProflessClassroom = false;
        },
        setIsAddingProflessClassroom: (state, action) => {
            state.isAddingProflessClassroom = action.payload;
        },

        setModifyClassroom: (state, action) => {
            state.modifyClassroomResult = action.payload;
            state.modifyClassroomError = null;
            state.isModifyingClassroom = false;
        },
        setModifyClassroomError: (state, action) => {
            state.modifyClassroomError = action.payload;
            state.modifyClassroomResult = null;
            state.isModifyingClassroom = false;
        },
        setIsModifyingClassroom: (state, action) => {
            state.isModifyingClassroom = action.payload;
        },
    }
});

export const {
    setClassroomList,
    setClassroomListError,
    setIsFetchingClassroomList,
    setTotalClassrooms,

    setAddClassroom,
    setAddClassroomError,
    setIsAddingClassroom,

    setAddProflessClassroom,
    setAddProflessClassroomError,
    setIsAddingProflessClassroom,

    setModifyClassroom,
    setModifyClassroomError,
    setIsModifyingClassroom,

} = classroomsSlice.actions;

export const fetchClassroomList = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: fetchClassroomsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setIsFetchingClassroomList(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setClassroomList(response.data?.classrooms));
            dispatch(setTotalClassrooms(response.data?.classroomCount))
        })
        .catch((response) => {
            message.error(response.response?.data?.message);
            dispatch(setClassroomListError(response.message));
        });


    return response.data;
}

export const addClassroom = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: addClassroomUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setIsAddingClassroom(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setAddClassroom(response.data));
        })
        .catch((response) => {
            message.error(response.response?.data?.message);
            dispatch(setAddClassroomError(response.message));
        });


    return response.data;
}

export const addProflessClassroom = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: addProflessClassroomUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setIsAddingProflessClassroom(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setAddProflessClassroom(response.data?.classroom));
        })
        .catch((response) => {
            message.error(response.response?.data?.message);
            dispatch(setAddProflessClassroomError(response.message));
        });


    return response.data;
}

export const modifyClassroom = (cookies, data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: modifyClassroomUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: cookies.token,
        },
        data
    };

    dispatch(setIsModifyingClassroom(true));
    const response = axios(config)
        .then((response) => {
            dispatch(setModifyClassroom(response.data));
        })
        .catch((response) => {
            message.error(response.response?.data?.message);
            dispatch(setModifyClassroomError(response.message));
        });


    return response.data;
}

export default classroomsSlice.reducer;