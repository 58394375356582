import { Button, Divider, InputNumber, Layout, Modal, Space, Spin, Table, message } from "antd";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
    ExclamationCircleTwoTone
} from '@ant-design/icons';
import PageHeader from "../../comps/pageHeader";
import EditProfForm from "../../comps/editProfForm";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { fetchProfs, getNumberOfReclaimableCodes, reclaimAllProfsCodes, reclaimSingleProfCodes, setNumberOfReclaimableCodes, setReclaimAllProfsCodesResult, setSuspendProfResult, suspendProf } from "./profsSlice";
import { useNavigate } from "react-router-dom";
import { getCodesCount } from "../activation/codesSlice";

const { Content } = Layout;

export default function ProfList({ pros }) {
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookies] = useCookies();
    const navigate = useNavigate();

    const CodeCountResult = useSelector((state) => state.codes.fetchCodeCountsResult);

    const [profToSuspend, setProfToSuspend] = useState(null);
    const [suspendModalOpen, setSuspendModalOpen] = useState(false);
    const [profToEdit, setProfToEdit] = useState(null);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [profToReclaim, setProfToReclaim] = useState(null);
    const [nCodesToReclaim, setNCodesToReclaim] = useState(0);
    const [reclaimModalOpen, setReclaimModalOpen] = useState(false);
    const [reclaimAllModalOpen, setReclaimAllModalOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchProfs(cookies));
        dispatch(getCodesCount(cookies));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const ProfList = useSelector((state) => state.profs.profList);
    const isFetchingProfs = useSelector((state) => state.profs.isFetchingProfs);
    const isSuspendingProf = useSelector((state) => state.profs.isSuspendingProf);
    const suspendProfResult = useSelector((state) => state.profs.suspendProfResult);
    const reclaimAllProfsCodesResult = useSelector((state) => state.profs.reclaimAllProfsCodesResult);
    const isReclaimingAllProfsCodes = useSelector((state) => state.profs.isReclaimingAllProfsCodes);
    const nReclaimableCodes = useSelector((state) => state.profs.numberOfReclaimableCodes);
    const isReclaimingSingleProfCodes = useSelector((state) => state.profs.isReclaimingSingleProfCodes);


    useEffect(() => {
        if (!suspendProfResult) {
            return;
        }

        message.success(suspendProfResult.message);
        dispatch(setSuspendProfResult(null));
        dispatch(fetchProfs(cookies));
        dispatch(getCodesCount(cookies));
        setSuspendModalOpen(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suspendProfResult]);

    useEffect(() => {
        if (reclaimAllProfsCodesResult === null || reclaimAllProfsCodesResult === undefined) {
            return;
        }
        if (reclaimAllProfsCodesResult < 1) {
            message.error("Aucun code à récupérer.");
        }
        else {
            message.success(`${reclaimAllProfsCodesResult} codes récupérés.`);
        }
        dispatch(setReclaimAllProfsCodesResult(null));
        dispatch(fetchProfs(cookies));
        dispatch(getCodesCount(cookies));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reclaimAllProfsCodesResult])

    const openBlockProfModal = (id) => {
        setProfToSuspend(id);
        setSuspendModalOpen(true);
    }

    const openReclaimModal = (id) => {
        setProfToReclaim(id);
        dispatch(getNumberOfReclaimableCodes(cookies, { id }));
    }

    const closeReclaimModal = () => {
        dispatch(setNumberOfReclaimableCodes(null));
        setNCodesToReclaim(1);
        setReclaimModalOpen(false);
    }

    useEffect(() => {
        if (nReclaimableCodes === null || nReclaimableCodes === undefined) {
            return;
        }

        setReclaimModalOpen(true);

    }, [nReclaimableCodes]);

    const ReclaimAllProfsCodes = () => {
        dispatch(reclaimAllProfsCodes(cookies));
    }

    const ReclaimSingleProfCodes = () => {
        if (nCodesToReclaim < 1) {
            return message.info("Vous n'avez pas assez de codes à récupérer sur ce prof.");
        }
        dispatch(reclaimSingleProfCodes(cookies, { id: profToReclaim, numberOfCodes: nCodesToReclaim }));
    }

    const SuspendProf = () => {
        if (!profToSuspend) {
            message.error("Une erreur inattendue est survenue, veuillez réessayer !");
        }
        dispatch(suspendProf(cookies, { id: profToSuspend }));
    }

    const openEditProfDrawer = (id) => {
        setProfToEdit(id);
        setEditDrawerOpen(true);
    }

    const columns = [
        {
            title: 'Prénom NOM',
            dataIndex: 'name',
            key: 'name',
            render: (text) => (<p>{text}</p>),
            width: "18vw",
            // ...getColumnSearchProps('code')
        },
        {
            title: 'Classes',
            dataIndex: 'classes',
            key: 'classes',
            render: (classList) => (<>
                {classList.map(element => {
                    return <p>{element.name}, </p>
                })}
            </>),
            width: "6vw",
            height: "12px",
            // ...getColumnSearchProps('code')
        },
        {
            title: 'Date d\'ajout',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (data) => (<p> {data ? dayjs(data).format("DD/MM/YYYY") : "/"}</p>),
            width: "100px",
            // ...getColumnSortByDateProps('createdAt')

        },
        {
            title: 'Nbr Codes Envoyés',
            dataIndex: 'numberOfActivationCodes',
            key: 'numberOfActivationCodes',
            render: (data) => (<p>{data}</p>),
            width: "100px",
            // ...getColumnFilterSentProps('sent')
        },
        {
            title: 'Codes Non utilisés',
            dataIndex: 'numberOfActivationCodesLeft',
            key: 'numberOfActivationCodesLeft',
            render: (data) => (<p>{data}</p>),
            width: "100px",
            // ...getColumnSortByDateProps('createdAt')

        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: '_id',
            render: (data, record) => (
                <div >
                    <Button
                        style={{ width: "6vw", marginRight: "8px", color: "#39B13D", border: "none" }}
                        onClick={() => { openEditProfDrawer(data); }}
                    >
                        Modifier
                    </Button>
                    <Button
                        disabled={record.status === "blocked"}
                        style={{ width: "6vw", marginRight: "8px", color: record.status === "blocked" ? "grey" : "#39B13D", border: "none" }}
                        onClick={() => { openBlockProfModal(data); }}
                    >
                        Suspendre
                    </Button>
                    <Button
                        disabled={record.status === "blocked" || CodeCountResult?.unusedCount < 1}
                        style={{ width: "6vw", marginRight: "8px", color: record.status === "blocked" ? "grey" : "#39B13D", border: "none" }}
                        onClick={() => { openReclaimModal(data) }}
                    >
                        Récupérer
                    </Button>
                </div>
            ),
            width: "18vw",
            // ...getColumnFilterStatusProps('sent')
        }

    ];

    return (
        <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
            <Layout>
                <PageHeader title="Liste des profs" breadcrumbItems={["Vue d'ensemble", "Liste des profs"]} />
                <Content>
                    <div style={{
                        margin: "2em",
                        padding: "2em 4vw 2em 4vw",
                        display: "flex",
                        direction: "column",
                        justifyContent: "space-around",
                        backgroundColor: "#424242"
                    }}>
                        <div className="buttonContainer">
                            <p style={{ color: "#fff", fontWeight: "600" }}>Ajouter un nouveau prof :</p>
                            <Button
                                className="buttonStyle"

                                onClick={() => navigate("/dashboard/profs/profList/create")}
                            >
                                Ajouter
                            </Button>
                        </div>
                        <div className="buttonContainer">
                            <p style={{ color: "#fff", fontWeight: "600" }}>Récupérer tous les codes non-utilisés :</p>
                            <Button
                                className="buttonStyle"

                                onClick={() => { setReclaimAllModalOpen(true) }}
                            >
                                {isReclaimingAllProfsCodes ? <Spin /> : "Récupérer"}
                            </Button>
                        </div>
                    </div>
                    <div
                        style={{
                            margin: "2em",
                            padding: "1em 2em 1em 2em",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            backgroundColor: "#fff"
                        }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}>
                            <h3>Liste des profs de l’établissement</h3>
                        </div>
                        <Divider />
                        <Table
                            loading={isFetchingProfs}
                            dataSource={ProfList}
                            pagination={{
                                total: ProfList?.length,
                                pageSize: 30,
                                showSizeChanger: false,
                                pageSizeOptions: [30], // , 15, 20, 30, 40, 50
                                showQuickJumper: true,
                                showTotal: (total, range) => (<>Nombre de profs : {total} </>),
                            }}
                            columns={columns}
                        // onChange={onChangePage}
                        />

                        <Modal
                            title={<div style={{ display: "flex", flexDirection: "row" }}>
                                <ExclamationCircleTwoTone twoToneColor="#FAAD14" style={{ marginRight: "8px" }} />
                                <p>Attention !</p>
                            </div>}
                            open={suspendModalOpen}
                            cancelText="Non"
                            onCancel={() => { setSuspendModalOpen(false) }}
                            okText={isSuspendingProf ? <Spin /> : "Oui"}
                            onOk={SuspendProf}
                        >
                            <p style={{ marginLeft: "16px" }}>Voulez-vous vraiment suspendre ce prof ?</p>
                        </Modal>

                        <Modal
                            title={<div style={{ display: "flex", flexDirection: "row" }}>
                                <ExclamationCircleTwoTone twoToneColor="#FAAD14" style={{ marginRight: "8px" }} />
                                <p>Attention !</p>
                            </div>}
                            open={reclaimAllModalOpen}
                            cancelText="Non"
                            onCancel={() => { setReclaimAllModalOpen(false) }}
                            okText={isReclaimingAllProfsCodes ? <Spin /> : "Oui"}
                            onOk={ReclaimAllProfsCodes}
                        >
                            <p style={{ marginLeft: "16px" }}>Voulez-vous vraiment récupérer tous les codes non-utilisés pendant 30 jours ?</p>
                        </Modal>

                        <Modal
                            title="Récupérer les codes non-utilisés"
                            open={reclaimModalOpen}
                            cancelText="Annuler"
                            onCancel={closeReclaimModal}
                            okText={isReclaimingSingleProfCodes ? <Spin /> : "Récupérer"}
                            onOk={ReclaimSingleProfCodes}
                        >
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <p style={{ marginRight: "8px" }}>Récupérer</p>
                                <InputNumber style={{ marginRight: "8px" }} min={0} max={nReclaimableCodes} value={nCodesToReclaim} onChange={(value) => setNCodesToReclaim(value)} />
                                <p style={{ color: "#888888", marginRight: "8px" }}>/{nReclaimableCodes}</p>
                                <p>Codes</p>
                            </div>
                        </Modal>
                    </div>
                    <EditProfForm open={editDrawerOpen} setEditDrawerOpen={setEditDrawerOpen} id={profToEdit} />
                </Content>
            </Layout>
        </Space>
    )
}