import React, { useState, useEffect } from "react";
import {
    Layout, Space, Button, Divider, Table, Badge,
    Input, Radio, Tooltip, Modal, InputNumber,
    Spin, Typography,
    message,
} from 'antd';
import {
    SendOutlined, ImportOutlined, SearchOutlined,
    FilterFilled, CaretDownFilled, CaretUpFilled,
    UserOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import PageHeader from "../../comps/pageHeader";
import AssignCodesToProf from "../../comps/codeAssignmentModal";

import { useDispatch, useSelector } from "react-redux";
import { ExportPDF, exportPDFSuccess, getActivationLinks, getCodes, getCodesCount, reclaimAllCodes, sendActivationCodesEmail, sendEmailsRequest, setReclaimCodesResult } from "./codesSlice";
import { useCookies } from 'react-cookie';
import { ReactMultiEmail } from "react-multi-email";
import { assignmentMode } from "../../comps/codeAssignmentModal";
import 'react-multi-email/dist/style.css';
import './activation.css';

const { Content } = Layout;
const { Text } = Typography;

export default function ActivationCodes({ props }) {

    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookies] = useCookies();

    const [timeout, setTimeoutVal] = useState(0);
    const [codeFilterDisplay, setCodeFilterDisplay] = useState("");
    const [filterData, setFilterData] = useState({
        page: 1,
        docsPerPage: 10,
        startDate: null,
        endDate: null,
        codeFilter: null,
        modulesFilter: null,
        dateSortOrder: -1,
        statusFilter: "both",
        sentFilter: "both"

    });

    const [numberOfCodesToExport, setNumberOfCodesToExport] = useState(1);
    const [numberOfCodesToSend, setNumberOfCodesToSend] = useState(1);

    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const [assignMode, setAssignMode] = useState(assignmentMode.ProfExists);

    const [typeOfSend, setTypeOfSend] = useState("1");

    // eslint-disable-next-line no-unused-vars
    const [selectedCode, setSelectedCode] = useState(null);

    const [emails, setEmails] = useState([]);




    useEffect(() => {
        dispatch(getCodesCount(cookies));
        dispatch(getCodes(cookies, filterData));
        dispatch(getActivationLinks(cookies));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData]);

    const CodeCountResult = useSelector((state) => state.codes.fetchCodeCountsResult);
    const CodeListResult = useSelector((state) => state.codes.fetchCodesResult);
    const isLoadingList = useSelector((state) => state.codes.isFetchingCodes);
    // const ModuleOptions = useSelector((state) => state.codes.fetchModuleOptionsResult);
    const ExportResponse = useSelector((state) => state.codes.exportPDFResult);
    const isExportingPDF = useSelector((state) => state.codes.isExportingPDF);
    const ActivationLinks = useSelector((state) => state.codes.activationLinksResult);
    const IsSendingEmails = useSelector((state) => state.codes.isFetchingSendEmails);
    const sendEmailsResult = useSelector((state) => state.codes.sendEmailsResult);

    const isReclaimingCodes = useSelector((state) => state.codes.isReclaimingCodes);
    const reclaimCodesResult = useSelector((state) => state.codes.reclaimCodesResult);

    const openAssignModal = () => {
        setIsAssignModalOpen(true);
    }

    const sendCodes = () => {
        let body = {
            // modules: ModuleOptions.options[selectedModulesExport].option,
            emails,
            isMultiple: typeOfSend === "2",
            singleCodeId: selectedCode
        };
        if (typeOfSend === "1") {
            body.count = numberOfCodesToSend;
        }
        else {
            body.count = emails.length;
        }
        dispatch(sendActivationCodesEmail(cookies, body))
    }

    useEffect(() => {
        if (!sendEmailsResult) return;
        dispatch(getCodesCount(cookies));
        dispatch(getCodes(cookies, filterData));
        dispatch(getActivationLinks(cookies));
        dispatch(sendEmailsRequest(null));
        setIsEmailModalOpen(false);
        message.success("Emails envoyés avec succès !");
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendEmailsResult])

    useEffect(() => {
        if (!ExportResponse) {
            return;
        }
        window.open(ExportResponse.fileLink, '_blank', 'noopener,noreferrer');
        setIsPDFModalOpen(false);

        dispatch(getCodesCount(cookies));
        dispatch(getCodes(cookies, filterData));
        // dispatch(getModulesExportOptions(cookies));
        dispatch(getActivationLinks(cookies))

        dispatch(exportPDFSuccess(null));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ExportResponse]);


    const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);

    const showPDFModal = () => {
        setIsPDFModalOpen(true);
    };

    const handlePDFOk = () => {
        dispatch(ExportPDF(cookies, { numberOfCodes: numberOfCodesToExport }))
    };

    const handlePDFCancel = () => {
        setIsPDFModalOpen(false);
    };

    const reclaimCodes = () => {
        dispatch(reclaimAllCodes(cookies));
    }

    useEffect(() => {
        if (!reclaimCodesResult) return;
        dispatch(getCodesCount(cookies));
        dispatch(getCodes(cookies, filterData));
        dispatch(getActivationLinks(cookies))
        dispatch(exportPDFSuccess(null));
        dispatch(setReclaimCodesResult(null));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reclaimCodesResult])

    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

    const showEmailModal = () => {
        setIsEmailModalOpen(true);
    };

    const handleEmailOk = () => {
        sendCodes();
        
    };

    const handleEmailCancel = () => {
        setIsEmailModalOpen(false);
    };

    const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);

    const showLinksModal = (text) => {

        setIsLinksModalOpen(true);
    };

    const handleLinksOk = () => {
        setIsLinksModalOpen(false);
    };

    const handleLinksCancel = () => {
        setIsLinksModalOpen(false);
    };

    const timedSearch = (data) => {
        setCodeFilterDisplay(data);
        let baseText = data;
        if (timeout) clearTimeout(timeout);
        setTimeoutVal(setTimeout(() => {
            let text = baseText ? baseText.replaceAll("-", "") : null;
            setFilterData({ ...filterData, codeFilter: text });
        }, 300));
    }

    const onChangePage = (e) => {
        setFilterData({ ...filterData, page: e.current, docsPerPage: e.pageSize });
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Tooltip placement="top" title={"Veuillez insérer un code complet"}>
                    <Input
                        // ref={searchInput}
                        placeholder={`XXXX-XXXX-XXXX-XXXX`}
                        value={codeFilterDisplay}
                        onChange={(e) => timedSearch(e.target.value)}

                        //   onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                </Tooltip>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => dispatch(getCodes(cookies, filterData))}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Chercher
                    </Button>
                    <Button
                        onClick={() => { setFilterData({ ...filterData, codeFilter: null }); setCodeFilterDisplay(null) }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });

    const getColumnSortByDateProps = (dataIndex) => ({
        filterDropdown: (<></>),
        filterIcon: (filtered) => (<>
            {filterData.dateSortOrder === 1 ?
                <CaretUpFilled
                    onClick={() => setFilterData({ ...filterData, dateSortOrder: -1 })}
                    style={{
                        color: '#39B13D',
                    }}
                /> :
                <CaretDownFilled
                    onClick={() => setFilterData({ ...filterData, dateSortOrder: 1 })}
                    style={{
                        color: '#39B13D',
                    }}
                />}
        </>),
    });

    const getColumnFilterStatusProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group onChange={(e) => setFilterData({ ...filterData, statusFilter: e.target.value })} value={filterData.statusFilter}>
                    <Space direction="vertical">
                        <Radio value={"1"}>Non Utilisés</Radio>
                        <Radio value={"0"}>Utilisés</Radio>
                        <Radio value={"both"}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });

    const getColumnFilterSentProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group onChange={(e) => setFilterData({ ...filterData, sentFilter: e.target.value })} value={filterData.sentFilter}>
                    <Space direction="vertical">
                        <Radio value={"false"}>Non Envoyés</Radio>
                        <Radio value={"true"}>Envoyés</Radio>
                        <Radio value={"both"}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });

    const columns = [
        {
            title: 'Code d\'accès',
            dataIndex: 'code',
            key: '_id',
            render: (text) => (<p>{text.match(/(.{4})/g).join("-")}</p>),
            width: "600px",
            ...getColumnSearchProps('code')
        },
        {
            title: 'Date d\'expiration',
            dataIndex: 'subscription',
            key: 'end_date',
            render: (data) => (<p> {data?.end_date ? dayjs(data.end_date).format("DD/MM/YYYY") : "/"}</p>),
            width: "100px",
            ...getColumnSortByDateProps('createdAt')

        },
        {
            title: 'Envoi',
            dataIndex: 'sent',
            key: 'sent',
            render: (data) => (<Badge color={data ? "#52C41A" : "#FAAD14"} text={data ? "Envoyé" : "Non Envoyé"} />),
            width: "100px",
            ...getColumnFilterSentProps('sent')
        },
        {
            title: 'Date d\'envoi',
            dataIndex: 'sentDate',
            key: 'sentDate',
            render: (data) => (<p>{data ? dayjs(data).format("DD/MM/YYYY") : "/"}</p>),
            width: "100px",
            ...getColumnSortByDateProps('createdAt')

        },
        {
            title: 'État',
            dataIndex: 'usesLeft',
            key: 'usesLeft',
            render: (data) => (<Badge color={data === 0 ? "#52C41A" : "#FAAD14"} text={data === 0 ? "Utilisé" : "Non Utilisé"} />),
            width: "100px",
            ...getColumnFilterStatusProps('sent')
        }

    ];

    return (
        <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
            <Layout>
                <PageHeader title={"Vos codes d'accès"} breadcrumbItems={["Vue d'ensemble", "Liste des codes d'accès"]} />
                <Content >
                    {/* Upper half of the page, basic code data and interaction buttons */}
                    <div style={{
                        margin: "2em",
                        padding: "2em 10vw 2em 10vw",
                        display: "flex",
                        direction: "column",
                        justifyContent: "space-between",
                        backgroundColor: "#fff"
                    }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ fontSize: "14px", color: "#00000073" }}>Nombre total de codes</h3>
                            <h1 style={{ fontSize: "24px", margin: "5px 5px 5px 0px" }}>{CodeCountResult ? CodeCountResult.allCodesCount : "NaN"}</h1>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ fontSize: "14px", color: "#00000073" }}>Codes Non Envoyés</h3>
                            <h1 style={{ fontSize: "24px", width: "fit-content", margin: "5px 5px 5px 0px" }}>{CodeCountResult ? CodeCountResult.unsentCount : "NaN"}</h1>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <h3 style={{ fontSize: "14px", color: "#00000073" }}>Codes Non Utilisés</h3>
                            <h1 style={{ fontSize: "24px", margin: "5px 5px 5px 0px" }}>{CodeCountResult ? CodeCountResult.unusedCount : "NaN"}</h1>
                        </div>
                    </div>
                    <div style={{
                        margin: "2em",
                        padding: "2em 4vw 2em 4vw",
                        display: "flex",
                        direction: "column",
                        justifyContent: "space-between",
                        backgroundColor: "#424242"
                    }}>
                        <div className="buttonContainer">
                            <p style={{ color: "#fff", fontWeight: "600" }}>Copier le lien d'activation</p>
                            <Button
                                className="buttonStyle"
                                // TODO: Switch this to a single link once the backend is done.
                                onClick={() => showLinksModal(ActivationLinks?.links[0])}
                            >
                                Copier
                            </Button>
                        </div>
                        <div className="buttonContainer">
                            <p style={{ color: "#fff", fontWeight: "600" }}>Attribuer à un prof</p>
                            <Button
                                className="buttonStyle"
                                onClick={() => openAssignModal()}
                            >
                                Attribuer
                            </Button>
                        </div>
                        <div className="buttonContainer">
                            <p style={{ color: "#fff", fontWeight: "600" }}>Exporter en PDF</p>
                            <Button
                                icon={<ImportOutlined dir="left" style={{ fontSize: "16px" }} />}
                                className="buttonStyle"
                                onClick={() => showPDFModal()}
                            >
                                Exporter
                            </Button>
                        </div>
                        <div className="buttonContainer">
                            <p style={{ color: "#fff", fontWeight: "600" }}>Envoyer des codes</p>
                            <Button
                                icon={<SendOutlined style={{ fontSize: "16px" }} />}
                                className="buttonStyle"
                                onClick={() => showEmailModal()}
                            >
                                Envoyer
                            </Button>
                        </div>
                        <div className="buttonContainer">
                            <p style={{ color: "#fff", fontWeight: "600" }}>Récupérer les codes non-utilisés</p>
                            <Button
                                disabled={CodeCountResult?.unusedCount < 1}
                                className="buttonStyle"
                                onClick={reclaimCodes}
                            >
                                {isReclaimingCodes ? <Spin/> : "Récupérer"}
                            </Button>
                        </div>
                    </div>
                    <div
                        style={{
                            margin: "2em",
                            padding: "1em 2em 1em 2em",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            backgroundColor: "#fff"
                        }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}>
                            <h3>Liste des Codes d’Accès</h3>
                        </div>
                        <Divider />
                        <Table
                            loading={isLoadingList}
                            dataSource={CodeListResult?.codes}
                            pagination={{
                                total: CodeListResult?.nCodes,
                                pageSize: filterData.docsPerPage,
                                showSizeChanger: true,
                                pageSizeOptions: [10, 15, 20, 30, 40, 50],
                                showQuickJumper: true,
                                showTotal: (total, range) => (<>Nombre de codes : {total} </>),
                            }}
                            columns={columns}
                            onChange={onChangePage}
                        />

                        <Modal title="Exporter sous format PDF" open={isPDFModalOpen} onOk={handlePDFOk} okText={isExportingPDF ? <Spin /> : "Exporter"} onCancel={handlePDFCancel}>
                            <Divider style={{ margin: "18px 0px 18px 0px" }} />
                            {/* <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Codes d'Accès :</p>
                                <Select
                                    style={{
                                        width: 250,
                                        height: "100%"
                                    }}
                                    value={selectedModulesExport}
                                    options={ModuleOptions?.options.map((elem, index) => { return { value: index, label: `Modules de ${elem.option[0]} à ${elem.option[1]} (${elem.count} restants)` } })}
                                    onChange={(e) => setSelectedModulesExport(e)}
                                />
                            </div> */}
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "8px"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Exporter :</p>
                                <InputNumber
                                    style={{
                                        width: 70
                                    }}
                                    // max={ModuleOptions?.options[selectedModulesExport]?.count}
                                    min={1}
                                    value={numberOfCodesToExport}
                                    onChange={(e) => { setNumberOfCodesToExport(e) }}
                                />
                                <p style={{ marginLeft: "8px" }}>codes</p>
                            </div>
                        </Modal>
                        <Modal title="Envoyer un ou plusieurs Codes" open={isEmailModalOpen} okText={IsSendingEmails ? <Spin /> : "Envoyer"} onOk={handleEmailOk} onCancel={handleEmailCancel}>
                            <Divider style={{ margin: "18px 0px 18px 0px" }} />
                            {/* <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Codes d'Accès :</p>
                                <Select
                                    style={{
                                        width: 250,
                                        height: "100%"
                                    }}
                                    value={selectedModulesSend}
                                    options={ModuleOptions?.options.map((elem, index) => { return { value: index, label: `Modules de ${elem.option[0]} à ${elem.option[1]} (${elem.count} restants)` } })}
                                    onChange={(e) => setSelectedModulesSend(e)}
                                />
                            </div> */}
                            {typeOfSend === "1" && (<div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "8px"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Envoyer :</p>
                                <InputNumber
                                    style={{
                                        width: 70
                                    }}
                                    // max={ModuleOptions?.options[selectedModulesSend]?.count}
                                    min={1}
                                    value={numberOfCodesToSend}
                                    onChange={(e) => { setNumberOfCodesToSend(e) }}
                                />
                                <p style={{ marginLeft: "8px" }}>codes</p>
                            </div>)}
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "16px"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Type d'envoi :</p>
                                <Radio.Group onChange={(e) => setTypeOfSend(e.target.value)} value={typeOfSend} defaultValue={"1"}>
                                    <Space direction="horizontal">
                                        <Radio value={"1"}>Un seul destinataire</Radio>
                                        <Radio value={"2"}>Plusieurs destinataires</Radio>
                                    </Space>
                                </Radio.Group>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "16px"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Envoyer à : </p>
                                {typeOfSend === "1" ? (<Input
                                    placeholder={`johndoe@email.com`}
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    value={emails[0]}
                                    onChange={(e) => setEmails([e.target.value])}

                                    style={{
                                        marginBottom: 8,
                                        width: 350,
                                    }}
                                />) :
                                    (<ReactMultiEmail
                                        emails={emails}
                                        onChange={(emails) => { setEmails(emails); }}
                                        style={{
                                            marginBottom: 8,
                                            width: 350,
                                        }}
                                        getLabel={(email, index, removeEmail) => {
                                            return (
                                                <div data-tag key={index}>
                                                    <div data-tag-item>{email}</div>
                                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                                        ×
                                                    </span>
                                                </div>
                                            );
                                        }}
                                    ></ReactMultiEmail>)
                                }
                            </div>

                        </Modal>
                        <Modal title="Copier le lien d'activation" open={isLinksModalOpen} onOk={handleLinksOk} onCancel={handleLinksCancel}>
                            <Divider style={{ margin: "18px 0px 18px 0px" }} />
                            <p>Lien :</p>
                            <Text copyable>{ActivationLinks?.links[0]}</Text>
                        </Modal>
                        {/* Assignment form modal */}
                        <AssignCodesToProf
                            isModalOpen={isAssignModalOpen}
                            mode={assignMode}
                            setAssignMode={setAssignMode}
                            handleCancel={() => setIsAssignModalOpen(false)}
                        />
                    </div>
                </Content>
            </Layout>
        </Space>
    )
}


